import { createRouter, createWebHistory } from '@ionic/vue-router';

export default createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/q/:quizId',
      name: 'QuizDetail',
      component: () => import('@/views/QuizDetail/index.vue'),
    },
    {
      path: '/',
      name: 'ErrorNotFound',
      redirect: '/q/832782',
    },
  ],
});
