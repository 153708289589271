export default {
  de: {
    overlays: {
      offline: {
        title: 'Du bist offline.',
        description: 'Um Flows nutzen zu können, ist eine Internetverbindung erforderlich.<br><br>Unser Team arbeitet schon daran, dass Du Flows bald auch offline nutzen kannst. ⚡️',
      },
    },
    tabNav: {
      today: 'Heute',
      discover: 'Entdecken',
      create: 'Erstellen',
      profile: 'Mehr',
    },
    greetings: {
      hello: (ctx) => {
        if (ctx.named('firstName')) {
          return `Hallo ${ctx.named('firstName')}.`;
        }

        return 'Hallo.';
      },
      hello2: (ctx) => {
        if (ctx.named('firstName')) {
          return `Moin ${ctx.named('firstName')}.`;
        }

        return 'Moin.';
      },
      hi: (ctx) => {
        if (ctx.named('firstName')) {
          return `Hi ${ctx.named('firstName')}.`;
        }

        return 'Hi.';
      },
      hey: (ctx) => {
        if (ctx.named('firstName')) {
          return `Hey ${ctx.named('firstName')}.`;
        }

        return 'Hey.';
      },
      ahoy: (ctx) => {
        let translatedString = '';

        translatedString = 'Ahoi';

        if (ctx.named('firstName')) {
          translatedString += `, ${ctx.named('firstName')}.`;
        } else {
          translatedString += '.';
        }

        return translatedString;
      },
      goodMorning: (ctx) => {
        if (ctx.named('firstName')) {
          return `Guten Morgen, ${ctx.named('firstName')}.`;
        }

        return 'Guten Morgen.';
      },
      goodAfternoon: (ctx) => {
        if (ctx.named('firstName')) {
          return `Hallo ${ctx.named('firstName')}.`;
        }

        return 'Hallo.';
      },
      goodEvening: (ctx) => {
        if (ctx.named('firstName')) {
          return `Guten Abend, ${ctx.named('firstName')}.`;
        }

        return 'Guten Abend.';
      },
    },
    errors: {
      email: {
        empty: 'Bitte gib eine E-Mail-Adresse ein.',
        invalidFormat: 'Bitte gib eine gültige E-Mail-Adresse ein.',
      },
      password: {
        empty: 'Bitte gib ein Passwort ein.',
        tooShort: 'Dein Passwort ist zu kurz. Es muss mindestens 10 Zeichen enthalten.',
        tooLong: 'Dein Passwort ist zu lang. Es darf maximal 80 Zeichen enthalten.',
        mustContainXNumbers: 'Dein Passwort muss mindestens zwei Zahlen enthalten.',
        mustUseLowercaseAndUppercase: 'Dein Passwort muss Groß- und Kleinbuchstaben enthalten.',
        mustContainSpecialCharacters: 'Dein Passwort muss mindestens ein Sonderzeichen enthalten.',
      },
      emailVerification: {
        codeInvalid: 'Der eingegebene Code ist ungültig.',
      },
      firstName: {
        empty: 'Bitte gib Deinen Vornamen ein.',
      },
      lastName: {
        empty: 'Bitte gib Deinen Nachnamen ein.',
      },
    },
    ui: {
      transferExerciseCard: {
        title: 'Deine Tagesaufgabe',
      },
    },
    units: {
      transferExerciseCard: {
        actions: {
          toDo: {
            completeButton: 'Aufgabe abschließen',
          },
        },
        loading: 'Wir haben\'s gleich …',
        success: {
          title: 'Gut gemacht, { firstName }.',
        },
      },
      manageSubscription: {
        loading: 'Momentchen noch …',
        noSubscription: {
          title: 'Erreiche mehr. Mit einem Flows-Paket.',
          description: 'Erhalte Zugriff auf alle Inhalte, erweiterte Personalisierung und regelmäßig neue Flows. Jederzeit kündbar.',
          viewPlansButton: 'Pakete ansehen',
        },
        clubHeadline: 'Dein Flows club-Paket, { firstName }.',
        clubPlusHeadline: 'Dein Flows club+-Paket, { firstName }.',
        memberSince: 'Paket aktiv seit { date }.',
        nextPayment: 'Abo wird am { date } für { price } { interval } verlängert.',
        expiration: 'Abo gekündigt. Du behältst Zugriff bis { date }.',
        stripe: {
          manageSubscriptionButton: 'Abo verwalten',
          redirectionInProgress: 'Du wirst gleich weitergeleitet …',
          footnote: 'Verwaltung Deines Abos über das Portal unseres Zahlungspartners Stripe.',
        },
      },
    },
    common: {
      nextUp: 'Als nächstes',
      clubMember: 'club Member',
      clubPlusMember: 'club+ Member',
      choosePlan: 'Paket wählen',
      yourProgress: 'Dein Fortschritt',
      save: 'Speichern',
      // eslint-disable-next-line no-irregular-whitespace
      percent: '{percent} %',
      // eslint-disable-next-line no-irregular-whitespace
      percentAccomplished: '{percent} % geschafft',
      pleaseSignInToContinue: 'Bitte melde Dich an, um fortzufahren.',
      weekDays: {
        twoLetters: {
          monday: 'Mo',
          tuesday: 'Di',
          wednesday: 'Mi',
          thursday: 'Do',
          friday: 'Fr',
          saturday: 'Sa',
          sunday: 'So',
        },
      },
      goal: {
        ofN: 'von {goal}',
      },
      time: {
        minutes: {
          short: '{min} Min.',
        },
        minutesSeconds: (ctx) => {
          let translatedString = '';

          const minutes = ctx.named('minutes');
          const seconds = ctx.named('seconds');

          const renderedMinutes = minutes < 10 ? `0${minutes}` : String(minutes);
          const renderedSeconds = seconds < 10 ? `0${seconds}` : String(seconds);

          translatedString = `${renderedMinutes}:${renderedSeconds}`;

          return translatedString;
        },
      },
      availableSoon: 'Bald verfügbar',
      auth: {
        signInWithApple: 'Mit Apple-ID anmelden',
        signInWithGoogle: 'Mit Google-Konto anmelden',
        signInWithEmail: 'Mit E-Mail anmelden',
        emailVerification: {
          didntReceiveCode: 'Code nicht erhalten?',
          reRequestCode: 'Erneut senden',
        },
      },
      ok: 'Ok',
      signIn: 'Anmelden',
      signUp: 'Registrieren',
      startFlow: 'Flow beginnen',
      flowIncludedInClubMembership: 'Enthalten in Deiner club-Mitgliedschaft',
      flowIncludedInClubPlusMembership: 'Enthalten in Deiner club+-Mitgliedschaft',
      highlights: 'Highlights',
      more: 'Mehr',
      less: 'Weniger',
      description: 'Beschreibung',
      share: 'Teilen',
      reportAProblem: 'Problem melden',
      proceed: 'Fortsetzen',
      imprint: 'Impressum',
      privacy: 'Datenschutz',
      termsOfUse: 'Nutzungsbedingungen',
      licenseInfo: 'Lizenzinformationen',
      company: 'Unternehmen',
      aboutSignature: 'Über signature',
      jobs: 'Jobs',
      support: 'Support',
      info: 'Info',
      signOut: 'Abmelden',
      designedAndEngineeredIn: 'Designed & Engineered in Düsseldorf',
      subscription: 'Abonnement',
      email: 'E-Mail',
      password: 'Passwort',
      next: 'Weiter',
      back: 'Zurück',
      finish: 'Abschließen',
      step: 'Schritt {step}',
      close: 'Schließen',
      cancel: 'Abbrechen',
      firstName: 'Vorname',
      lastName: 'Nachname',
      perMonth: 'pro Monat',
      perYear: 'pro Jahr',
    },
    widgets: {
      weeklyStreak: {
        label: 'Session diese Woche | Sessions diese Woche',
      },
    },
    modals: {
      more: {
        modalTitle: 'Mehr',
        title: 'Mehr',
      },
      authWithEmail: {
        signIn: {
          modalTitle: 'Anmelden mit E-Mail',
          title: 'Mit E-Mail-Adresse anmelden',
          welcome: 'Willkommen zurück, <b>{email}. ✌️</b>',
          forgotPassword: 'Passwort vergessen?',
          useDifferentEmail: 'Andere E-Mail-Adresse verwenden?',
          legalHint: 'Mit dem Klick auf "Anmelden" stimmst Du unserer Datenschutzerklärung, unseren Nutzungsbedingungen und unseren allgemeinen Geschäftsbedingungen zu.',
        },
        signUp: {
          title: 'Mit E-Mail-Adresse registrieren',
          signUpHint: 'Du bist neu hier: Willkommen! 🎉<br>Wir erstellen jetzt gemeinsam einen Account für <b>{email}</b>.',
          useDifferentEmail: 'Andere E-Mail-Adresse verwenden',
          legalHint: 'Mit dem Klick auf "Registrieren" stimmst Du unserer Datenschutzerklärung, unseren Nutzungsbedingungen und unseren allgemeinen Geschäftsbedingungen zu.',
        },
        resetPassword: {
          title: 'Passwort zurücksetzen',
        },
        emailVerification: {
          title: 'Nur noch ein Schritt.',
          description: 'Fast geschafft! Schau jetzt bitte in Deinem E-Mail-Postfach ({email}) nach einem <b>sechsstelligen</b> Verifizierungcode, den wir Dir gesendet haben.<br>Gib diesen bitte anschließend unten ein.',
        },
        passwordRequirements: {
          minLength: 'Enthält 10 Zeichen',
          containsNumbers: 'Enthält 2 Zahlen',
          usesLowercaseAndUppercase: 'Enthält Groß-/Kleinbuchstaben',
          containsSpecialCharacters: 'Enthält 1 Sonderzeichen ($, %, !, ?, *, …)',
        },
        errors: {
          common: {
            title: 'Anmeldung nicht möglich.',
            description: 'Bitte überprüfe nochmal Deine E-Mail-Adresse und Dein Passwort und versuche es dann erneut.',
          },
          commonSignUpError: {
            title: 'Registrierung nicht möglich.',
            description: 'Wir konnten keinen Account für <b>{ email }</b> erstellen. Möglicherweise existiert bereits ein Account mit dieser E-Mail-Adresse oder es gab ein Problem mit der Verarbeitung Deiner Anfrage.<br>Versuche es bitte erneut.',
          },
        },
      },
      choosePlan: {
        header: {
          title: 'Paket wählen',
        },
        clubPlan: {
          eyebrow: 'club-Paket',
          title: 'Ein Abo. Alle Inhalte.',
          titleFreeTrial: 'Ein Abo. Alle Inhalte.<br>14 Tage kostenlos.',
          benefits: {
            accessToAllOriginals: 'Zugriff auf alle Inhalte (1 Flow/Monat)',
            aiPersonalization: 'Effektiver lernen dank KI (kommt bald)',
            sustainableKnowledgeBuilding: 'Nachhaltig neues Wissen aufbauen',
          },
        },
        clubPlusPlan: {
          eyebrow: 'club+-Paket',
          title: 'Skills nachhaltig entwickeln.',
          titleFreeTrial: 'Skills nachhaltig entwickeln.<br>14 Tage kostenlos.',
          benefits: {
            accessToAllOriginals: 'Zugriff auf alle Inhalte (unbegrenzte Anzahl an Flows)',
            aiPersonalization: 'Effektiver lernen dank KI (kommt bald)',
            sustainableKnowledgeBuilding: 'Nachhaltig neues Wissen aufbauen',
            knowledgeTransfer: 'Wissen mit Challenges & Aufgaben im Alltag anwenden',
          },
        },
        success: {
          title: 'Danke für Deinen Einkauf.',
          descriptionClubMembership: 'Viel Erfolg mit Deinem club-Paket, { firstName }.',
          descriptionClubPlusMembership: 'Viel Erfolg mit Deinem club+-Paket, { firstName }.',
          closeModal: 'Los geht\'s',
        },
        startTrial: '14-tägige Testphase starten',
        startPlan: 'Jetzt Paket abonnieren',
        trialSubtitleMonthly: 'Danach monatlich { price }. Jederzeit kündbar.',
        trialSubtitleYearly: 'Danach jährlich { price }. Jederzeit kündbar.',
        subtitleMonthly: '{ price }/Monat. Jederzeit kündbar.',
        subtitleYearly: '{ price }/Jahr. Jederzeit kündbar.',
        club: 'club',
        clubPlus: 'club+',
        loading: 'Einen Moment bitte …',
        gotDiscountCodeLabel: 'Du hast einen Rabattcode?',
        gotDiscountCodeDescription: 'Nach dem Klick über den unteren Button, wirst Du auf die Zahlungsseite unseres Zahlungspartners weitergeleitet. Hier kannst Du auch Deinen Rabattcode einlösen.',
        subscribeButtonProgress: 'Einen Moment bitte …',
        subscribeButtonSuccess: 'Wir leiten Dich weiter …',
        terms: {
          termsOfUse: 'Nutzungsbedingungen',
          generalTermsAndConditions: 'AGB',
          privacyPolicy: 'Datenschutz',
        },
      },
      session: {
        blocks: {
          finishSession: {
            title: 'Bist Du fertig##COMMA_FIRST_NAME##?',
            completeSessionButton: 'Session abschließen',
            backToSessionButton: 'Zurück zur Session',
          },
        },
        results: {
          title: 'Gut gemacht##COMMA_FIRST_NAME##.',
          flowCompletedTitle: 'Herzlichen Glückwunsch##COMMA_FIRST_NAME##.',
          flowCompletedDescription: 'Du hast den Flow <b>{ flowName }</b> erfolgreich abgeschlossen. Gut gemacht!',
          completeButton: 'Abschließen',
          time: {
            minutesSuffix: ' Min',
            label: 'Mach weiter so.',
          },
        },
      },
      sessionWarning: {
        modalTitle: 'Warnung',
        continueButton: 'Session trotzdem starten',
        cancelButton: 'Abbrechen',
        maximumRecommendedDailySessionsReached: {
          title: 'Möchtest Du wirklich fortfahren?',
          description: 'Um einen nachhaltigen Lernfortschritt zu erzielen, empfehlen wir Dir <mark>pro Tag maximal eine Session</mark> abzuschließen.<br>Komm doch morgen wieder vorbei. | Um einen nachhaltigen Lernfortschritt zu erzielen, empfehlen wir Dir <mark>pro Tag maximal eine Session</mark> abzuschließen.<br>Komm doch morgen wieder vorbei. | Um einen nachhaltigen Lernfortschritt zu erzielen, empfehlen wir Dir <mark>pro Tag maximal { n } Sessions</mark> abzuschließen.<br>Komm doch morgen wieder vorbei.',
        },
      },
    },
    flow: {
      progressCard: {
        description: (ctx) => {
          const sessionsLeft = Number(ctx.named('sessionsLeft'));
          const flowName = ctx.named('flowName');
          const firstName = ctx.named('firstName');

          if (sessionsLeft === 0) {
            return `Gut gemacht, ${firstName}. Du hast heute alle Sessions abgeschlossen.`;
          }

          if (sessionsLeft === 1) {
            return `Absolviere heute noch eine Session, um <strong>${flowName}</strong> bis zu Deinem Ziel abzuschließen.`;
          }

          return `Absolviere heute noch ${sessionsLeft} Sessions, um <strong>${flowName}</strong> bis zu Deinem Ziel abzuschließen.`;
        },
        progressLabel: '<strong>{flowName}</strong> • {flowProgress} %',
      },
    },
    session: {
      greetings: {
        morning: {
          firstUse: {
            goodMorning: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Morgen und willkommen zu Deiner ersten Session';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
          welcome: {
            goodMorning: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Morgen';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
        },
        noon: {
          firstUse: {
            goodNoon: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Mittag und willkommen bei Deiner ersten Session';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
          welcome: {
            goodNoon: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Mittag';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
        },
        evening: {
          firstUse: {
            goodEvening: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Abend und willkommen bei Deiner ersten Session';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
          welcome: {
            goodEvening: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Abend';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
        },
        night: {
          firstUse: {
            goodEvening: (ctx) => {
              let translatedString = '';

              translatedString = 'Guten Abend und willkommen bei Deiner ersten Session';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
          welcome: {
            welcomeLate: (ctx) => {
              let translatedString = '';

              translatedString = 'Willkommen';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}. 🦉`;
              } else {
                translatedString += '. 🦉';
              }

              return translatedString;
            },
          },
        },
        common: {
          firstUse: {
            default: (ctx) => {
              let translatedString = '';

              translatedString = 'Willkommen bei Deiner ersten Session';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
          welcome: {
            welcome: (ctx) => {
              let translatedString = '';

              translatedString = 'Willkommen';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
            warmWelcome: (ctx) => {
              let translatedString = '';

              translatedString = 'Herzlich willkommen';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
            hello: (ctx) => {
              let translatedString = '';

              translatedString = 'Hallo';

              if (ctx.named('firstName')) {
                translatedString += ` ${ctx.named('firstName')}. 👋`;
              } else {
                translatedString += '. 👋';
              }

              return translatedString;
            },
            helloInformal: (ctx) => {
              let translatedString = '';

              translatedString = 'Moin';

              if (ctx.named('firstName')) {
                translatedString += ` ${ctx.named('firstName')}. 👋`;
              } else {
                translatedString += '. 👋';
              }

              return translatedString;
            },
            hi: (ctx) => {
              let translatedString = '';

              translatedString = 'Hi';

              if (ctx.named('firstName')) {
                translatedString += ` ${ctx.named('firstName')}. 👋`;
              } else {
                translatedString += '. 👋';
              }

              return translatedString;
            },
            hey: (ctx) => {
              let translatedString = '';

              translatedString = 'Hey';

              if (ctx.named('firstName')) {
                translatedString += ` ${ctx.named('firstName')}. 👋`;
              } else {
                translatedString += '. 👋';
              }

              return translatedString;
            },
            niceToSeeYou: (ctx) => {
              let translatedString = '';

              translatedString = 'Schön, dass Du da bist';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
            ahoy: (ctx) => {
              let translatedString = '';

              translatedString = 'Ahoi';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
          longTimeNoSee: {
            longTimeNoSee: (ctx) => {
              let translatedString = '';

              translatedString = 'Lange nicht gesehen';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              translatedString += '<br/>Schön, dass Du wieder da bist.';

              return translatedString;
            },
            welcomeBack: (ctx) => {
              let translatedString = '';

              translatedString = 'Willkommen zurück';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
            niceToSeeYouAgain: (ctx) => {
              let translatedString = '';

              translatedString = 'Schön, dass Du wieder da bist';

              if (ctx.named('firstName')) {
                translatedString += `, ${ctx.named('firstName')}.`;
              } else {
                translatedString += '.';
              }

              return translatedString;
            },
          },
        },
      },
      hints: {
        tapRight: 'Zum Fortfahren rechts tippen',
      },
      dialog: {
        pause: {
          title: 'Session pausiert',
          cancel: 'Session abbrechen',
        },
      },
    },
    views: {
      onboarding: {
        title: 'Lerne Deine Skills der Zukunft.',
        description: 'Personalisierte Wissensvermittlung für Deine persönliche Entwicklung.',
        setupButton: 'Los geht\'s',
        signInHint: 'Schon dabei?',
        interests: {
          title: 'Welche Themen interessieren Dich?',
        },
        goals: {
          title: 'Welche Ziele möchtest Du erreichen?',
          gainMoreGeneralKnowledge: 'Mehr Allgemeinwissen aufbauen',
          learnNewSkills: 'Neue Fähigkeiten lernen',
          understandFutureTopics: 'Zukunftsthemen verstehen',
          developMyself: 'Mich selbst weiterentwickeln',
        },
        plannedUsage: {
          title: 'Wofür möchtest Du Flows nutzen?',
          personal: 'Für mich selbst',
          forWork: 'Berufliche Weiterbildung',
          forSchool: 'Schule/Studium',
        },
        signUp: {
          title: 'Du hast es fast geschafft.',
          description: 'Als nächstes erstellen wir gemeinsam eine signature id.<br><br>Das ist Dein persönlicher Account, über den Du alle Inhalte auf all Deinen Geräten abrufen kannst.',
        },
        choosePlan: {
          title: 'Zugriff auf alle Inhalte.<br>14 Tage kostenlos.',
          description: 'Mit einem Flows-Abo hast Du Zugriff auf alle Inhalte.',
          choosePlanButton: 'Paket wählen',
          chooseLater: 'Später wählen',
        },
      },
      today: {
        headlineYourDay: 'Dein Tag',
        headlineYourProgress: 'Dein Fortschritt',
        loading: 'Wir haben\'s gleich.',
        transferExercise: {
          title: 'Deine Tagesaufgabe',
        },
        progress: {
          title: 'Dein Fortschritt',
        },
        noFlowSelected: {
          eyebrow: 'Leg los',
          title: 'Wähle einen Flow, um zu beginnen.',
        },
        completedAllSessions: {
          title: (ctx) => {
            let translatedString = '';

            translatedString = 'Gut gemacht';

            if (ctx.named('firstName')) {
              translatedString += `, ${ctx.named('firstName')}.`;
            } else {
              translatedString += '.';
            }

            return translatedString;
          },
          description: 'Das wars für heute.<br>Komm morgen wieder um Deine nächste Session abzuschließen.',
        },
      },
      discover: {
        title: 'Entdecken',
        loadingFlows: 'Sind gleich so weit.',
      },
      profile: {
        title: 'Mehr',
        signOut: {
          dialog: {
            title: 'Abmeldung bestätigen',
            description: 'Wenn Du Dich abmeldest, werden zwischengespeicherte Daten von Deinem Gerät gelöscht und Du musst Dich erneut mit Deiner signature id anmelden.',
            failedTitle: 'Abmeldung fehlgeschlagen',
            failedDescription: 'Es gab ein Problem beim Abmelden. Bitte versuche es erneut.',
          },
        },
      },
      flowDetail: {
        loadingFlow: 'Einen Moment noch.',
        requiresClubMembership: 'Erfordert eine club-Mitgliedschaft.',
        choosePlanAndContinue: 'Paket wählen und fortfahren',
        newContentAddedRegularly: {
          title: 'Freu Dich auf mehr.',
          description: 'Die Flows-App ist noch ganz neu. Daher sind noch nicht alle Inhalte verfügbar.<br><br>Unser Team arbeitet bereits daran, in den kommenden Wochen regelmäßig neue Flows zu veröffentlichen. Bleib gespannt.',
        },
        noFlowCreditsLeft: {
          description: 'Du hast Dein Guthaben für Flows verbraucht. Dein neues Guthaben kommt am { renewalDate }.<br><br>Du willst nicht warten?',
          upgradeToClubPlusButton: 'Upgrade auf club+-Paket',
        },
        cancelCurrentFlow: {
          title: 'Aktuellen Flow abbrechen?',
          description: 'Wenn Du fortfährst wird <b>{flowName}</b> abgebrochen und Dein Fortschritt gelöscht.<br><br>Möchtest Du fortfahren?',
          cancelFlow: 'Neuen Flow beginnen',
          close: 'Abbrechen',
        },
        alreadyActive: 'Dein aktuell aktiver Flow',
      },
      flowSetup: {
        title: 'Flow einrichten',
        description: 'Wir stellen Dir jetzt ein paar Fragen, damit wir den Flow an Dich und Dein Vorwissen anpassen können.',
        selfAssessment: {
          title: 'Wie schätzt Du Deine Kenntnisse ein?',
          description: 'Mit Deiner Einschätzung können wir Inhalte überspringen, die Du schon kennst und den Fokus auf neue Learnings legen.',
          noExperience: 'Keine Erfahrung',
          basicKnowledge: 'Grundlegendes Wissen',
          advancedKnowledge: 'Fortgeschrittenes Wissen',
        },
        interest: {
          title: 'Wie sehr interessiert Dich das Thema dieses Flows?',
          noInterest: 'Nicht interessiert',
          lowInterest: 'Ein wenig interessiert',
          mediumInterest: 'Mittelmäßig interessiert',
          highInterest: 'Interessiert',
          veryHighInterest: 'Sehr interessiert',
          excited: 'Ich kanns kaum erwarten 🤩',
        },
        summary: {
          title: 'Du bist startklar.',
          description: 'Wir haben den Flow mit Deinen Angaben personalisiert.',
        },
        personalizingTitle: 'Personalisierung läuft.',
        personalizingDescription: 'Wir richten jetzt alles für Dich ein.<br>Einen Moment bitte. ⚡️',
      },
      settings: {
        profileSettings: 'Profileinstellungen',
        notifications: 'Benachrichtigungen',
      },
      settingsProfile: {
        title: 'Profileinstellungen',
        personalInfoTitle: 'Deine Infos',
        otherSettingsTitle: '',
        access: {
          title: 'Zugang',
          emailLabel: 'E-Mail-Adresse ändern',
          passwordLabel: 'Passwort ändern',
        },
        deleteAccount: {
          title: 'Account löschen',
          deleteAccountLabel: 'Account löschen',
        },
      },
      settingsNotifications: {
        title: 'Benachrichtigungen',
      },
      settingsSubscriptions: {
        title: 'Abonnement',
      },
      onboardingAuth: {
        title: 'Anmelden mit signature id',
        benefits: {
          syncBetweenDevices: 'Fortschritte zwischen Deinen Geräten synchronisieren',
          accessToContent: 'Zugriff auf Deine Inhalte',
        },
      },
      subscriptionCheckoutSuccessful: {
        title: 'Willkommen an Bord, { firstName }.',
        club: {
          description: 'Yeah! 🥳<br>Deine Zahlung für Dein club-Paket war erfolgreich. Viel Erfolg damit, { firstName }.',
        },
        clubplus: {
          description: 'Yeah! 🥳<br>Deine Zahlung für Dein club+-Paket war erfolgreich. Viel Erfolg damit, { firstName }.',
        },
        buttons: {
          discoverFlows: 'Flows entdecken',
        },
      },
      subscriptionCheckoutCanceled: {
        title: 'Zahlung abgebrochen',
        description: 'Die Zahlung Deines Pakets wurde abgebrochen. Ein Abo wurde nicht abgeschlossen.',
        buttons: {
          retry: 'Erneut versuchen',
          done: 'Abschließen'
        },
      },
      imprint: {
        title: 'Impressum',
        aboutText: 'signature technologies ist ein junges Start-up, das mit innovativen Technologien die persönliche Weiterentwicklung revolutioniert.<br><br>Als Zukunftsunternehmen möchten wir Menschen dazu ermutigen, ihre eigene Zukunft zu gestalten, indem wir ihnen die Tools und das Wissen zur Verfügung stellen, mit dem sie ihre Ziele erreichen.',
        imprintLegalBasis: 'Angaben gemäß § 5 TMG.',
        companyName: 'signature technologies UG (haftungsbeschränkt)',
        address: 'KÖ-QUARTIER<br>Breite Straße 22<br>40213 Düsseldorf',
        representedBy: 'Vertreten durch',
        managingDirector: 'Geschäftsführer: Gianluca Dörnenburg',
        vatIdTitle: 'Umsatzsteuer-ID',
        vatId: 'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE351155024',
        editoriallyResponsibleTitle: 'Redaktionell verantwortlich',
        editoriallyResponsible: 'Gianluca Dörnenburg<br>Breite Straße 22<br>40213 Düsseldorf',
        contactTitle: 'Kontakt',
        climateTitle: 'Gut fürs Klima. Seit Gründung.',
        climateDescription: 'Unsere Infrastruktur wird umweltfreundlich betrieben, wir arbeiten papierlos, wählen unsere Dienstleister nach ökologischen Aspekten aus und gleichen unvermeidbare CO2-Ausstöße durch die Unterstützung von klimaschützenden Projekten aus.',
        euDisputeResolutionTitle: 'EU-Streitschlichtung',
        euDisputeResolutionDescription: 'Plattform der EU zur Online-Streitbeilegung (OS): <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener">https://ec.europa.eu/consumers/odr/</a><br><br>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
        commercialRegisterNumber: 'Handelsregister: HRB 95774',
        commercialRegister: 'Registergericht: Amtsgericht Düsseldorf',
      },
      privacy: {
        title: 'Datenschutz',
      },
      termsOfUse: {
        title: 'Nutzungsbedingungen',
      },
      licenseInfo: {
        title: 'Lizenzinformationen',
      },
    },
  },
};
