<template>
  <div id="app">
    <app-offline-overlay/>
    <ion-app>
      <ion-page>
        <ion-router-outlet id="ion-router-outlet-content" />
      </ion-page>
    </ion-app>
  </div>
</template>

<script>
// import { mapActions } from 'vuex';
import useUserStore from '@/stores/UserStore';
import { IonApp, IonPage, IonRouterOutlet } from '@ionic/vue';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
// import { Keyboard } from '@capacitor/keyboard';
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';
import auth from '@/env/auth';
import env from '@/env';

import AppOfflineOverlay from '@/components/AppOfflineOverlay.vue';
import AppPreloader from '@/components/layout/AppPreloader.vue';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    AppOfflineOverlay,
    AppPreloader,
  },
  data() {
    return {
      showPreloader: true,
    };
  },
  methods: {
    // ...mapActions(['getUser']),
    async setupStatusBarTheme() {
      if (env.platform !== 'web') {
        await StatusBar.setStyle({
          style: Style.Dark,
        });
      }
    },
  },
  computed: {
  },
  async created() {
    // App Startup Routine
    this.setupStatusBarTheme();

    if (env.platform !== 'web') {
      SuppressLongpressGesture.activateService();
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }

    // Scroll focused form fields into view
    // Keyboard.addListener('keyboardDidShow', () => {
    //   document.activeElement.scrollIntoView(true);
    // });

    // INIT AUTH
    // auth.init(async (result) => {
    //   if (result.status === 'signed-in') {
    //     // request user info from server
    //     await this.userStore.getUser({
    //       idToken: result.idToken,
    //     });
    //     this.showPreloader = false;
    //   } else {
    //     await this.userStore.getUser({
    //     });
    //     this.showPreloader = false;
    //   }
    // });
  },
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    };
  },
};
</script>

<style>
/* manrope-regular - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/manrope/manrope-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/manrope/manrope-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/manrope/manrope-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/manrope/manrope-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/manrope/manrope-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/manrope/manrope-v4-latin-regular.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-500 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/manrope/manrope-v4-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/manrope/manrope-v4-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/manrope/manrope-v4-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/manrope/manrope-v4-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/manrope/manrope-v4-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/manrope/manrope-v4-latin-500.svg#Manrope') format('svg'); /* Legacy iOS */
}

:root {
  color-scheme: dark;
  --default-content-padding: 1.5rem;
  --default-content-max-width: 650px; /* 700px */
  --default-card-padding: 1.8rem;
  --default-padding: 1.5em;
  --default-padding-large: 1.8em;
  --default-margin: 1em;
  --default-border-radius: 1.3em;
  --default-logo-width: 4em;
  --default-gradient-tilt: 18deg;
  --header-height: calc(3.5em + var(--safe-area-top));
  --bottom-nav-height: 64px;
  --default-button-gap: 1em;

  /* --font-size-primary: 3.052rem;
  --font-size-secondary: 2.441rem;
  --font-size-tertiary: 1.953rem;
  --font-size-quaternary: 1.563rem; */

  --font-size-primary: 2.1rem;
  --font-size-secondary: 1.65rem;
  --font-size-tertiary: 1.3rem;
  --font-size-quaternary: 1.25rem;

  --font-weight-medium: 500;

  --line-height-small: 1.65em;

  --color-primary: #30EDB0;
  --color-secondary: #B8FC5E;
  --color-primary-transparency: rgba(48, 237, 176, 0.29);
  --color-secondary-transparency: rgba(184, 252, 94, 0.29);
  --color-text: #DBDBDB;
  --color-text-secondary: #A8A8A8;
  --color-text-light: #ffffff;
  --color-modal-background: #050505;
  --color-background: #050505;
  --color-background-secondary: #171717; /* before: #2f2f2f */
  --color-background-tertiary: #363636;
  --color-background-quaternary: #5c5c5c;
  --color-background-transparent: rgba(245, 245, 245, 0);

  --color-overlay-background: #050505b9;

  --color-field: #242424;
  --color-field-active: #292929;
  --color-field-border: #333333;
  --color-field-border-active: #3f3f3f;

  --color-glass-primary: #696969a9;

  --color-error: #FF3D5A;
  --color-warning: #FFBE0C;
  --color-success: #5EC42B;
  --color-info: #197CFF;

  --color-background-transparent: rgba(5, 5, 5, 0);

  --color-card-border: rgba(117, 117, 117, 0.9);
  --color-card-outline-secondary: #1b1b1b;

  --gradient-branding-primary: linear-gradient(80deg, var(--color-primary), var(--color-secondary));
  --gradient-branding-primary-transparency: linear-gradient(80deg, var(--color-primary-transparency), var(--color-secondary-transparency));
  --gradient-branding-secondary: linear-gradient(80deg, var(--color-primary-20), var(--color-secondary-20));
  --gradient-heading: linear-gradient(80deg, #ABF6DE, #E2F9C6);
  --gradient-glass-primary: linear-gradient(120deg, rgba(228, 228, 228, 0.4), rgba(57, 57, 58, 0.4));
  /* light: --gradient-glass-primary: linear-gradient(120deg, rgba(192, 206, 201, 0.5), rgba(216, 216, 216, 0.2)); */

  --default-gradient-floor-fade: linear-gradient(to top, #050505c0, transparent);
  --default-text-shadow: 0em 0em 0.4em #05050594;

  --ion-color-primary: var(--color-primary);

  --font-size-small: 0.9em;
  --font-size-x-small: 0.7em;

  --margin-buttons: 0.5em;

  --space-modal-top-l: 5em;

  --blur-overlay-background: blur(0.19em);

  --breakpoint-mobile: 600px;
  --max-content-width: 850px;
  --safe-area-top: var(--ion-safe-area-top);
  --safe-area-bottom: var(--ion-safe-area-bottom);
  --safe-area-left: var(--ion-safe-area-left);
  --safe-area-right: var(--ion-safe-area-right);
}

@media (max-width: 600px) {
  :root {
    --default-card-padding: 1.5em;
    --default-button-gap: 0.5em;
  }
}

@media (min-width: 767px) {
  :root {
    --color-modal-background: #111111;
  }
}

.card-margin {
  margin-bottom: 0.5em;
}

.card-margin:last-child {
  margin-bottom: 0em;
}

.lightmode-only {
  display: block;
}

.darkmode-only {
  display: none;
}

.dark .lightmode-only {
  display: none;
}

.dark .darkmode-only {
  display: block;
}

.dialog-content {
  margin-bottom: 2em;
}

.dialog-button-spacing {
  margin-bottom: 0.8em;
}

* {
  box-sizing: border-box;

  /* DISABLE UNWANTED MENUS ON IOS */
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;
}

html, body {
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: 1.69em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  text-rendering: geometricPrecision;
  overscroll-behavior: none;
  word-break: break-word;
  hyphens: manual;
  -webkit-hyphens: manual;
  font-size: 17px;
  user-select: none;
  -webkit-user-select: none;
}

::-moz-selection {
  background: var(--color-primary);
  color: var(--color-background);
}

::selection {
  background: var(--color-primary);
  color: var(--color-background);
}

ion-content {
  --ion-background-color: var(--color-background);
  --ion-text-color: var(--color-text);
}

.session-modal ion-content {
  --ion-background-color: transparent !important;
}

ion-modal.session-modal {
  --ion-background-color: transparent !important;
}

ion-modal ion-content {
  --ion-background-color: var(--color-modal-background) !important;
}

ion-modal {
  --border-radius: 1.2em !important;
}

body, h1, h2, h3, h4, h5, h6, p, span, i, b, a, strong, button, input, textarea {
  font-family: 'Manrope';
  color: var(--color-text);
  margin: 0px;
}

p {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.65em;
}

mark, .mark-primary {
  background: var(--gradient-branding-primary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

mark.headline {
  background: var(--gradient-heading);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

strong, b {
  font-weight: 500 !important;
}

.title-top-space {
  margin-top: 0.5em;
}

h1, .f-primary {
  font-size: var(--font-size-primary);
  font-weight: 500;
  padding-bottom: 0.8rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 3.3rem;
  letter-spacing: -0.02em;
  background: var(--gradient-heading);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2, .f-secondary {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  padding-bottom: 0.9rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 2.5rem;
  background: var(--gradient-heading);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3, .f-tertiary {
  font-size: var(--font-size-tertiary);
  font-weight: 500;
  padding-bottom: 0.5rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 2rem;
  background: var(--gradient-heading);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h4, .f-quaternary {
  font-size: var(--font-size-quaternary);
  font-weight: 500;
  padding-bottom: 0.5rem; /* without padding, letters like "g" would be displayed cut off */
  line-height: 1.8rem;
  background: var(--gradient-heading);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.f-footnote {
  font-size: var(--font-size-small);
}

.f-small {
  font-size: var(--font-size-small);
}

.title-space-bottom {
  margin-bottom: 0.5em;
}

.headline-space-top {
  margin-top: 2em;
}

.f-text-large {
  font-size: 1.2em;
  line-height: 1.65em;
}

.signature-logo-icon {
  width: 1.9em;
}

@media (max-width: 650px) {
  h1 {
    line-height: 1.2em;
  }
}

p {
  margin-bottom: 2rem;
}

p:last-child {
  margin-bottom: 0em;
}

.field-space-right {
  padding-right: 0.25em;
}

.field-space-left {
  padding-left: 0.25em;
}

/* utility classes */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: inherit;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: inherit;
}

.truncate-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: inherit;
}

.button-margin-b {
  margin-bottom: 0.4em;
}

.card-margin {
  margin-bottom: 1em;
}

.card-margin:last-child {
  margin-bottom: 0em;
}

.fw-medium {
  font-weight: 500 !important;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
}

a.color-text {
  color: var(--color-text);
}

.color-error {
  color: var(--color-error);
}

input, textarea {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;

  font-size: 1em;
  font-weight: 400 !important;
}

input:focus, textarea:focus, button:focus, a:focus {
  outline: none;
}

.view-content {
  min-height: 100vh;
}

.text-list-item-space-b {
  margin-bottom: 0.5em;
}

.color-primary {
  color: var(--color-primary);
}
/* .view {
  height: 100%;
  min-height: calc(100vh - var(--header-height));
} */

.default-loader-space {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

.inner-content {
  width: 100%;
  max-width: var(--max-content-width);
  margin: auto;
}

.content-padding-lr {
  padding-left: var(--default-padding);
  padding-right: var(--default-padding);
}

.content-padding-tb {
  /* padding-top: var(--default-padding); */
  padding-top: 2em;
  padding-bottom: var(--default-padding);
}

.safe-area-top {
  margin-top: var(--header-height);
}

.align-center {
  text-align: center;
}

.logo {
  width: var(--default-logo-width);
}

.text-input .label, .textarea-input .label {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 18px;
  text-align: left;
}

.text-input input, .textarea-input textarea {
  background-color: var(--color-card-1);
  padding: 0.8em;
  border-radius: var(--default-border-radius);
  margin-bottom: var(--default-margin);

  cursor: text;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background-color: #ffffff !important;
  opacity: 0.4;
}

/* VIEW TRANSITIONS */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;

  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.view-fade-in .ion-page {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* INTRO TRANSITIONS */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.4em, 0);
    transform: translate3d(0, 0.4em, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.4em, 0);
    transform: translate3d(0, 0.4em, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fade-in-up {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.4em, 0);
    transform: translate3d(0, 0.4em, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.4em, 0);
    transform: translate3d(0, 0.4em, 0);
  }
}

.fade-out-down {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.delay-100 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.delay-144 {
  -webkit-animation-delay: 0.144s;
  animation-delay: 0.144s;
}

.delay-200 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-288 {
  -webkit-animation-delay: 0.288s;
  animation-delay: 0.288s;
}

.delay-300 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.delay-400 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-432 {
  -webkit-animation-delay: 0.432s;
  animation-delay: 0.432s;
}

.delay-500 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-550 {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.delay-576 {
  -webkit-animation-delay: 0.576s;
  animation-delay: 0.576s;
}

.delay-600 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-700 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.delay-1200 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay-1300 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.delay-1400 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay-1800 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.d-fullscreen {
  height: calc(100vh - (var(--header-height) + var(--default-content-padding)));
}

/* CONFETTI */
#confetti-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

/* SESSION MODAL */
ion-modal.session-modal {
  --width: 100vw;
  --height: 100vh;
  --border-radius: 0px !important;
}

.session-modal .modal-wrapper {
  width: 100vw !important;
  height: 100vh !important;
}

/* UTILITY CLASSES */
.small-only {
  display: block;
}

.large-only {
  display: none;
}

@media (min-width: 800px) {
  .small-only {
    display: none;
  }

  .large-only {
    display: block;
  }
}

/* IONIC */

body.backdrop-no-scroll {
  overflow: hidden;
}

ion-modal {
  --ion-background-color: var(--color-background-secondary);
}

html.ios ion-modal.modal-card .ion-page > ion-header > ion-toolbar:first-of-type {
  padding-top: 0px;
}

html.ios ion-modal .ion-page {
  border-radius: inherit;
}

.ion-color-primary {
  --ion-color-base: var(--color-primary) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-primary-shade, #3171e0) !important;
  --ion-color-tint: var(--ion-color-primary-tint, #4c8dff) !important;
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary, #3dc2ff) !important;
  --ion-color-base-rgb: var(--ion-color-secondary-rgb, 61, 194, 255) !important;
  --ion-color-contrast: var(--ion-color-secondary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-secondary-shade, #36abe0) !important;
  --ion-color-tint: var(--ion-color-secondary-tint, #50c8ff) !important;
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary, #5260ff) !important;
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb, 82, 96, 255) !important;
  --ion-color-contrast: var(--ion-color-tertiary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-tertiary-shade, #4854e0) !important;
  --ion-color-tint: var(--ion-color-tertiary-tint, #6370ff) !important;
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success, #2dd36f) !important;
  --ion-color-base-rgb: var(--ion-color-success-rgb, 45, 211, 111) !important;
  --ion-color-contrast: var(--ion-color-success-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-success-shade, #28ba62) !important;
  --ion-color-tint: var(--ion-color-success-tint, #42d77d) !important;
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning, #ffc409) !important;
  --ion-color-base-rgb: var(--ion-color-warning-rgb, 255, 196, 9) !important;
  --ion-color-contrast: var(--ion-color-warning-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-warning-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-warning-tint, #ffca22) !important;
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger, #eb445a) !important;
  --ion-color-base-rgb: var(--ion-color-danger-rgb, 235, 68, 90) !important;
  --ion-color-contrast: var(--ion-color-danger-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-danger-shade, #cf3c4f) !important;
  --ion-color-tint: var(--ion-color-danger-tint, #ed576b) !important;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium, #92949c) !important;
  --ion-color-base-rgb: var(--ion-color-medium-rgb, 146, 148, 156) !important;
  --ion-color-contrast: var(--ion-color-medium-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-medium-shade, #808289) !important;
  --ion-color-tint: var(--ion-color-medium-tint, #9d9fa6) !important;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark, #222428) !important;
  --ion-color-base-rgb: var(--ion-color-dark-rgb, 34, 36, 40) !important;
  --ion-color-contrast: var(--ion-color-dark-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-dark-shade, #1e2023) !important;
  --ion-color-tint: var(--ion-color-dark-tint, #383a3e) !important;
}

.checkmark-circle-space-b {
  padding-bottom: 1.5em;
}

.checkmark-circle-s-space-b {
  padding-bottom: 0.4em;
}

.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
}

.split-pane-visible > .ion-page.split-pane-main {
  position: relative;
}

ion-route,
ion-route-redirect,
ion-router,
ion-select-option,
ion-nav-controller,
ion-menu-controller,
ion-action-sheet-controller,
ion-alert-controller,
ion-loading-controller,
ion-modal-controller,
ion-picker-controller,
ion-popover-controller,
ion-toast-controller,
.ion-page-hidden,
[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.ion-page-invisible {
  opacity: 0;
}

.can-go-back > ion-header ion-back-button {
  display: block;
}

html.plt-ios.plt-hybrid, html.plt-ios.plt-pwa {
  --ion-statusbar-padding: 20px;
}

@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
ion-card.ion-color .ion-inherit-color,
ion-card-header.ion-color .ion-inherit-color {
  color: inherit;
}

.menu-content {
  transform: translate3d(0,  0,  0);
}

.menu-content-open {
  cursor: pointer;
  touch-action: manipulation;
  pointer-events: none;
}

.ios .menu-content-reveal {
  box-shadow: -8px 0 42px rgba(0, 0, 0, 0.08);
}

[dir=rtl].ios .menu-content-reveal {
  box-shadow: 8px 0 42px rgba(0, 0, 0, 0.08);
}

ion-button.nostyle {
  padding: 0px !important;
  height: auto !important;
  width: auto !important;
  background: transparent !important;
  color: inherit;

  --background: transparent;
  --background-hover: transparent;
  --background-focused: transparent;
  --background-activated: transparent;
  --padding-bottom: 0.2em;
  --padding-top: 0em;
  --padding-end: 0em;
  --padding-start: 0em;
  --border-radius: 0em;
  margin-top: -0.4em;
}

ion-button.nostyle:deep(button) {
  background: transparent !important;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

ion-content::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
}
</style>
